<template>
  <div>
    <NavBar></NavBar>
    <b-container>
      <h4 class="mt-2">Feedback</h4>
      <hr>
      <p>I would love to have your feedback! </p>
      <p>If you spotted an error or have some ideas to improve this book, do not hesitate to share them with me!</p>
      <ContactForm context="feedback"></ContactForm>
    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>

const title = "Feedback - Practical Go Lessons"
const description = "Post your Feedback on the book"

import ContactForm from "@/components/ContactForm";
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
name: "Feedback",
  components: {Footer, NavBar, ContactForm},
  created() {
    window.scrollTo(0,0);
  },
  metaInfo: {
    title: title,
    htmlAttrs: {
      lang: 'en',
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: description },
      { name: 'robots', content: "index, follow" },
      { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
      { property: 'og:type', content: "website" },
      { property: 'og:title', content: title},
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:site_name', content: 'Practical Go Lessons' },
      { property: 'twitter:card', content: "summary_large_image" },
      { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
    ],
    link : [
      { rel : "canonical", href : window.location.href}
    ]

  }
}
</script>

<style scoped>

</style>