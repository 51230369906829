<template>
  <div>
    <b-row>
      <b-col sm="2" md="6">
        <b-form @submit="onSubmit" class="border p-4" >

          <h2>{{title}}</h2>
          <p>{{explanation}}</p>

          <b-form-group id="input-group-firstname" label="Your name (optional):" label-for="input-firstname">
            <b-form-input
                id="input-firstname"
                v-model="form.name"
                placeholder="Enter your name:"
            ></b-form-input>
          </b-form-group>

          <b-form-group
              id="input-group-email"
              :label="emailLabel"
              label-for="input-email"
          >
            <b-form-input
                id="input-email"
                v-model="form.email"
                type="email"
                :required="emailRequired"
                placeholder="Enter your email"
            ></b-form-input>
          </b-form-group>

          <div v-if="this.form.additionalData.formType === 'order' ">
            <b-form-group label="*Format:"  >
              <b-form-radio v-model="form.additionalData.orderType"  name="some-radios" value="HARD_COLOR">Color</b-form-radio>
              <b-form-radio v-model="form.additionalData.orderType"  name="some-radios" value="HARD_BLACK_AND_WHITE">Black & White</b-form-radio>
              <b-form-radio v-model="form.additionalData.orderType"  name="some-radios" value="DIGITAL">Digital</b-form-radio>
            </b-form-group>
          </div>

          <b-form-group
              id="input-group-message"
              :label="messageLabel"
              label-for="input-message">
            <b-form-textarea
                id="input-message"
                v-model="form.message"
                :required="messageRequired"
                rows="6"
                max-rows="6"
                placeholder="Your message:"
            ></b-form-textarea>
          </b-form-group>
          <div class="text-center">
            <b-button type="submit" variant="success" block >Send !</b-button>
          </div>
        </b-form>

      </b-col>

    </b-row>

  </div>
</template>

<script>
import Webservice from "@/webservice";
import Consts from "@/consts";

export default {
  name: "ContactForm",
  components: {},
  props: {
    context : String,
    title: String,
    explanation: String
  },
  data() {
    return {
      emailLabel:'',
      emailRequired: false,
      messageLabel: '',
      messageRequired : false,

      form: {
        email: '',
        name: '',
        message: '',
        context: this.context,
        additionalData : {
          formType: '',
          orderType: ''
        }
      },
      contexts : Consts.CONTACT_FORM_CONTEXT
    }
  },
  created: function() {
    this.emailLabel="*Email:"
    this.emailRequired = true
    this.messageLabel = "Your message (optional):"
    this.messageRequired = false
    if (this.context === Consts.CONTACT_FORM_CONTEXT.ORDER_DIGITAL_COPY){
      this.form.additionalData.formType = 'order'
      this.form.additionalData.orderType = 'DIGITAL'
    } else if (this.context === Consts.CONTACT_FORM_CONTEXT.ORDER_HARD_COPY_BW){
      this.form.additionalData.formType = 'order'
      this.form.additionalData.orderType = 'HARD_BLACK_AND_WHITE'
    } else if (this.context === Consts.CONTACT_FORM_CONTEXT.ORDER_HARD_COPY_COL) {
      this.form.additionalData.formType = 'order'
      this.form.additionalData.orderType = 'HARD_COLOR'
    } else if (this.context === Consts.CONTACT_FORM_CONTEXT.COUPON) {
      this.form.additionalData.formType = 'coupon'
    } else {
      this.form.additionalData.formType = 'feedback'
      this.messageLabel = "*Your message:"
      this.messageRequired = true
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      Webservice.createContact(this.form).then(()=>{
        this.form.email =''
        this.form.name =''
        this.form.message =''
        this.makeToast("Success !", "Thanks I will get back to you ASAP", "success")
      }).catch(()=>{
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      })
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
  }
}
</script>

<style scoped>

</style>